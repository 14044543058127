import { AnimatePresence, motion } from "framer-motion";
import Link from "next/link";
import { Dispatch, SetStateAction } from "react";
type Props = {
	showMsg: number;
	Styles: any;
	setShowMsg: Dispatch<SetStateAction<number>>;
	administrator: boolean | undefined;
	locale: string | undefined;
};
export default function UserMenuItem({
	showMsg,
	Styles,
	setShowMsg,
	administrator,
	locale,
}: Props) {
	return (
		<AnimatePresence>
			<motion.div
				className={Styles.openMenu__userMenu}
				initial={{ height: 0 }}
				style={showMsg === 4 ? { padding: 0 } : { padding: 0 }}
				animate={showMsg === 4 ? { height: "auto" } : { height: 0 }}
				exit={{ height: 0 }}
				transition={{ type: "spring", duration: 0.4 }}
			>
				<div
					className={Styles.menuProfile}
					style={
						showMsg !== 4 ? { visibility: "hidden" } : { visibility: "visible" }
					}
				>
					<Link href={"/perfil"} style={{ textDecoration: "none" }}>
						<span className={Styles.itemTitle}>Acesse sua carteira</span>
					</Link>

					<Link href={"/perfil"} style={{ textDecoration: "none" }}>
						<span
							onClick={() => {
								localStorage.setItem("buyCNLTSessionDesktop", "true");
								setShowMsg(0);
							}}
							className={Styles.itemTitle}
						>
							Comprar CNLT
						</span>
					</Link>

					<Link href={"/perfil"} style={{ textDecoration: "none" }}>
						<span
							className={Styles.itemTitle}
							onClick={() => {
								localStorage.setItem("buyDesktop", "true");
								setShowMsg(0);
							}}
						>
							Invista em projetos
						</span>
					</Link>

					<Link href={"/perfil#history"} style={{ textDecoration: "none" }}>
						<span className={Styles.itemTitle}>Histórico de transações</span>
					</Link>

					<Link href={"/perfil"} style={{ textDecoration: "none" }}>
						<span
							className={Styles.itemTitle}
							onClick={() => {
								localStorage.setItem("saque", "true");
								setShowMsg(0);
							}}
						>
							Realizar saque
						</span>
					</Link>

					<Link
						style={{ textDecoration: "none" }}
						href="mailto:contato@blocklize.tech"
						locale={locale}
					>
						<span className={Styles.itemTitle}>Ajuda</span>
					</Link>

					<Link href={"/logout"} style={{ textDecoration: "none" }}>
						<span className={Styles.itemTitle}>Desconectar</span>
					</Link>
					{administrator && (
						<div>
							<div className={Styles.alternative2} />
							<Link
								href={"/controle"}
								style={{ textDecoration: "none" }}
							>
								<span
									className={Styles.itemTitle}
									onClick={() => {
										setShowMsg(0);
									}}
								>
									Controle administrador
								</span>
							</Link>
						</div>
					)}
				</div>
			</motion.div>
		</AnimatePresence>
	);
}
