import { useState, useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import Styles from "./styles.module.scss";

import { fetchDataUserInfo } from "@/utils/fetchDataAxios";

import { AnimatePresence } from "framer-motion";
import Image from "next/image";
import SourceWhite from "@/assets/img/logo-white.webp";
import HeaderItem from "@/components/molecules/HeaderItem";
import HeaderProfile from "@/components/molecules/HeaderProfile";
import FirstItem from "./MenuItems/FirstItem";
import SecondItem from "./MenuItems/SecondItem";
import { User } from "@/types/User";
import ThirdItem from "./MenuItems/ThirdItem";
import UserMenuItem from "./MenuItems/UserMenuItem";
import LanguageItem from "./MenuItems/LanguageItem";
import pt from '@/public/locales/pt/common.json';
import en from '@/public/locales/en/common.json';
import es from '@/public/locales/es/common.json';

type ILanguage = {
	language: string;
}[];
const Header = ({ envs }: any) => {
	const [showMsg, setShowMsg] = useState<number>(0);
	const [dataUser, setDataUser] = useState<User>();
	const { locale } = useRouter();

	const router = useRouter();

	function handleLanguage(locale: string) {
		switch (locale) {
			case 'en': return en
			case 'pt': return pt
			case 'es': return es
			default: return pt
		}
	}
	const t:any = handleLanguage(locale ? locale : 'es')


	useEffect(() => {
		// console.log(window.location.pathname)
		const accessToken = localStorage.getItem("accessToken");

		fetchDataUserInfo(
			accessToken,
			setDataUser,
			router,
			envs.NEXT_PUBLIC_GET_USER_INFO
		);

		// console.log(dataUser)
		// router.push(`${beforePath}`)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div style={{ position: "fixed", width: "100%", zIndex: 100000 }}>
			<nav className={Styles.navContainer}>
				<div className={Styles.itemsContainer}>
					<div className={Styles.imgLogo}>
						<Link href={"/quem-somos"}>
							<Image src={SourceWhite} alt="dsa" fill />
						</Link>
					</div>

					<div style={{ marginLeft: 20 }}>
						<HeaderItem
							setShowMsg={setShowMsg}
							name={t.Header.CoinLivre.Title}
							msg={1}
							showMsg={showMsg}
						/>
					</div>
					<div>
						<HeaderItem
							setShowMsg={setShowMsg}
							name={t.Header.MeetProjects.Title}
							msg={2}
							showMsg={showMsg}
						/>
					</div>
					<div>
						<HeaderItem
							setShowMsg={setShowMsg}
							name={t.Header.Blog.Title}
							msg={3}
							showMsg={showMsg}
						/>
					</div>
					<div>
						<HeaderItem
							setShowMsg={setShowMsg}
							name={t.Header.Language.Title}
							msg={5}
							showMsg={showMsg}
						/>
					</div>
				</div>

				<div className={Styles.loginButtonContainer}>
					{!dataUser?.name ? (
						<div className={Styles.loginButton} style={t === es ? { width: "240px"} : {}}>
							<Link href={"/login"}>
								<button style={{border: 0}}>{t.Header.SignUpOrLogIn}</button>
							</Link>
						</div>
					) : (
						<div style={{ display: "flex", alignItems: "center", gap: 20 }}>
							<HeaderProfile
								setShowMsg={setShowMsg}
								showMsg={showMsg}
								msg={4}
								name={`${t.hello}, ${dataUser?.name}`}
							/>
						</div>
					)}
				</div>
			</nav>
			<div style={{ display: "flex", justifyContent: "space-evenly" }}>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						gap: 0,
						minWidth: 580,
					}}
				>
					<AnimatePresence>
						<FirstItem key={"1"} Styles={Styles} showMsg={showMsg} t={t} />
						<SecondItem
							key={"2"}
							Styles={Styles}
							dataUser={dataUser}
							setShowMsg={setShowMsg}
							showMsg={showMsg}
							t={t}
						/>
						<ThirdItem key={"3"} Styles={Styles} showMsg={showMsg} t={t} />
						<LanguageItem
							key={"4"}
							Styles={Styles}
							showMsg={showMsg}
							setShowMsg={setShowMsg}
							t={t}
						/>
					</AnimatePresence>
				</div>
				<div>
					<UserMenuItem
						Styles={Styles}
						administrator={dataUser?.userAdmin}
						locale={locale}
						setShowMsg={setShowMsg}
						showMsg={showMsg}
					/>
				</div>
			</div>
		</div>
	);
};

export default Header;
